var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row gutter-b"},[_c('div',{staticClass:"col-12 col-md-3 pt-0 pb-0"},[_c('b-form-select',{attrs:{"options":_vm.optionYear},model:{value:(_vm.selected.year),callback:function ($$v) {_vm.$set(_vm.selected, "year", $$v)},expression:"selected.year"}})],1),_c('div',{staticClass:"col-12 col-md-3 pt-0 pb-0"},[_c('b-form-select',{attrs:{"options":_vm.optionMonth},model:{value:(_vm.selected.month),callback:function ($$v) {_vm.$set(_vm.selected, "month", $$v)},expression:"selected.month"}})],1),(
        _vm.permission.some((item) => item.name === 'can_view_dashboard') ||
        _vm.currentUser.role.name == 'Client'
      )?_c('div',{staticClass:"col-12 col-md-3 pt-0 pb-0"},[_c('div',{staticClass:"card gutter-b"},[_c('b-form-select',{attrs:{"options":_vm.filterCustomer},model:{value:(_vm.selectCustomer),callback:function ($$v) {_vm.selectCustomer=$$v},expression:"selectCustomer"}})],1)]):_vm._e(),(
        _vm.permission.some((item) => item.name === 'can_view_dashboard') ||
        _vm.currentUser.role.name == 'Client'
      )?_c('div',{staticClass:"col-12 col-md-3 pt-0 pb-0"},[_c('div',{staticClass:"card gutter-b"},[_c('b-form-select',{attrs:{"options":_vm.filterCustomerBranch},model:{value:(_vm.selected.customerBranch),callback:function ($$v) {_vm.$set(_vm.selected, "customerBranch", $$v)},expression:"selected.customerBranch"}})],1)]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-6 mb-2"},[_c('b-card',[_c('LineChart',{attrs:{"chartData":_vm.chartDataPresence}})],1)],1),_c('div',{staticClass:"col-xxl-6"},[_c('b-card',[_c('BarChart',{attrs:{"chartData":_vm.chartDataPatrol}})],1)],1),_c('div',{staticClass:"col-xxl-6"},[_c('b-card',[_c('BarChart',{attrs:{"chartData":_vm.chartDataDailyReport}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }